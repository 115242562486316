<template>
  <div class="page-intro questionnaire-background graphite">
    <header class="page-intro__header page-intro-header">
      <questionnaire-language-dropdown
        class="page-intro-header__language-dropdown"
        :value="locale"
        :options="languageOptions"
        @input="$emit('change-language', $event)"
      />
    </header>

    <div class="page-intro__content page-intro-content">
      <section class="page-intro-section doctor-info-section">
        <img
          class="page-content-intro__doctor-image"
          width="90"
          height="90"
          src="@/assets/images/doctor/default-doctor-image.svg"
          alt="doctor icon"
        />

        <div class="doctor-info-section__quote grey600 q-desktop-only q-mt8">
          {{ $t(doctorQuote) }}
        </div>

        <div class="doctor-info-section__doctor-signature q-mt8">
          {{ doctorName }}
        </div>
        <h1 class="u-typography-helvetica u-text-display u-text-display--s q-m0 q-mt24">
          {{ pageHeading }}
        </h1>
        <div class="u-typography-helvetica u-text u-text--s grey700 q-mt16">
          {{ $t('startDiagnostic.intro') }}
        </div>

        <u-button class="q-mt16" @click="onStartDiagnostic">{{
          $t('startPage.action.startDiagnostic')
        }}</u-button>
      </section>

      <section class="personalized-chart-section q-mt32 q-mt40-md">
        <h2
          class="u-typography-helvetica u-text u-text--link u-text--m grey600 q-m0 q-mb16 q-mb24-md"
        >
          {{ $t('startDiagnostic.youWillGet') }}
        </h2>

        <h4 class="u-typography-helvetica u-text u-text--l q-mt16 q-mt24-md q-mb16 q-mb24-md">
          {{ $t('startDiagnostic.personalizedChart') }}
        </h4>

        <img
          class="q-centered q-block"
          src="@/modules/questionnaire/assets/images/intro-page/you-get-image.jpg"
          width="151"
          height="157"
          alt=""
        />

        <span class="personalized-chart-section__ai-tag q-mt16">{{
          $t('startDiagnostic.poweredByAI')
        }}</span>

        <div class="u-typography-helvetica u-text u-text--s grey700 q-mt16">
          {{ $t('startDiagnostic.uniqueSkinDiag') }}
        </div>

        <spider-chart
          class="q-mt16 q-mt24-md q-mb24 q-mb32-md"
          data-screenshot-target
          :dysfunctions="demoDysfunctions"
        />
      </section>

      <div class="page-intro-content__section-divider"></div>

      <section class="q-mt24 q-mt32-md">
        <h4 class="u-typography-helvetica u-text u-text--l">
          {{ $t('startDiagnostic.doctorDiagAndRecommendations') }}
        </h4>

        <div class="recommended-ingredients q-mt24 q-mb24 q-mb40-md">
          <img
            class="recommended-ingredients__image"
            src="@/modules/questionnaire/assets/images/intro-page/recommendations-image.jpg"
            width="138"
            height="149"
            alt=""
          />

          <div class="">
            <h5
              class="recommended-ingredients__title u-typography-helvetica u-text u-text--link u-text--m"
            >
              {{ $t('startDiagnostic.mostRecommendedIngridients') }}
            </h5>

            <ul class="recommended-ingredients__recommendations-list">
              <li class="recommended-ingredients-list-item">
                <u-icon class="recommended-ingredients__check-icon" name="check-circle" />
                <span
                  class="recommended-ingredients__color-square recommended-ingredients__color-square--skin-dryness"
                />
                {{ $t('dashboard.ingredients.dpanthenol') }}
              </li>

              <li class="recommended-ingredients-list-item">
                <u-icon class="recommended-ingredients__check-icon" name="check-circle" />
                <span
                  class="recommended-ingredients__color-square recommended-ingredients__color-square--skin-redness"
                />
                {{ $t('dashboard.ingredients.niacinamide') }}
              </li>

              <li class="recommended-ingredients-list-item">
                <u-icon class="recommended-ingredients__check-icon" name="check-circle" />
                <span
                  class="recommended-ingredients__color-square recommended-ingredients__color-square--oxidative-stress"
                />
                {{ $t('dashboard.ingredients.sod') }}
              </li>

              <li class="recommended-ingredients-list-item">
                <u-icon class="recommended-ingredients__check-icon" name="check-circle" />
                <span
                  class="recommended-ingredients__color-square recommended-ingredients__color-square--skin-appearance"
                />
                {{ $t('dashboard.ingredients.madeca') }}
              </li>
            </ul>
          </div>
        </div>
      </section>

      <div class="page-intro-content__section-divider"></div>

      <section class="personalized-offer-section">
        <h4 class="u-typography-helvetica u-text u-text--l">
          {{ $t('startDiagnostic.personalizedTreatmentOffer') }}
        </h4>

        <div class="page-intro__gray-background-section best-skin-section">
          <img
            class="best-skin-section__image"
            src="@/modules/questionnaire/assets/images/intro-page/personalized-offer-image.svg"
            width="313"
            height="52"
            alt=""
          />
          <span class="u-typography-helvetica u-text u-text--xs grey500 q-mt16">
            <span>Best skin,</span>
            <br />
            <span>your doctor &amp; skincareactivist</span>
          </span>
        </div>
      </section>

      <div class="page-intro-content__section-divider q-mt24 q-mt32-md"></div>

      <section class="three-easy-steps-section q-mt32 q-mt40-md">
        <h2 class="u-typography-helvetica u-text u-text--link u-text--m grey600">
          {{ $t('startDiagnostic.completeThreeSteps') }}
        </h2>

        <ul class="page-intro__gray-background-section">
          <li class="q-mb24 easy-step">
            <img
              class="easy-step__image"
              width="72"
              height="72"
              src="@/modules/questionnaire/assets/images/intro-page/take-photo-step.svg"
              alt="take a photo"
            />

            <div class="easy-step__text-block">
              <h4 class="u-typography-helvetica u-text u-text--link u-text--m easy-step__title">
                {{ $t('startDiagnostic.step1.title') }}
              </h4>

              <div class="u-typography-helvetica u-text u-text--xs grey600">
                {{ $t('startDiagnostic.step1.description') }}
              </div>
            </div>
          </li>

          <li class="q-mb24 easy-step">
            <img
              class="easy-step__image"
              width="72"
              height="72"
              src="@/modules/questionnaire/assets/images/intro-page/answer-questions-step.svg"
              alt="answer questions"
            />

            <div class="easy-step__text-block">
              <h4 class="u-typography-helvetica u-text u-text--link u-text--m easy-step__title">
                {{ $t('startDiagnostic.step2.title') }}
              </h4>

              <div class="u-typography-helvetica u-text u-text--xs grey600">
                {{ $t('startDiagnostic.step2.description') }}
              </div>
            </div>
          </li>

          <li class="easy-step">
            <img
              class="easy-step__image"
              width="72"
              height="72"
              src="@/modules/questionnaire/assets/images/intro-page/get-advice-step.svg"
              alt="get advice"
            />

            <div class="easy-step__text-block">
              <h4 class="u-typography-helvetica u-text u-text--link u-text--m easy-step__title">
                {{ $t('startDiagnostic.step3.title') }}
              </h4>

              <div class="u-typography-helvetica u-text u-text--xs grey600">
                {{ $t('startDiagnostic.step3.description') }}
              </div>
            </div>
          </li>
        </ul>

        <u-button class="q-mt32 q-mt40-md q-centered" @click="onStartDiagnostic">{{
          $t('startPage.action.startDiagnostic')
        }}</u-button>
      </section>
    </div>
  </div>
</template>

<script>
import { UButton, UIcon, SpiderChart } from 'universkin-shared';
import { spiderChartDysfunctionsMixin } from 'universkin-shared/src/commonDomainObjects/mixins/spiderChartDysfunctionsMixin';

import QuestionnaireLanguageDropdown from '@/modules/questionnaire/new-design-components/QuestionnaireLanguageDropdown';

import { DYSFUNCTION_NAMES } from '@/modules/questionnaire/constants/dysfunctions';

const DEMO_SCORES = {
  [DYSFUNCTION_NAMES.OXIDATIVE_STRESS]: 7,
  [DYSFUNCTION_NAMES.SKIN_APPEARANCE]: 3,
  [DYSFUNCTION_NAMES.SKIN_REDNESS]: 8,
  [DYSFUNCTION_NAMES.SKIN_DRYNESS]: 6,
  [DYSFUNCTION_NAMES.SKIN_OILINESS]: 3,
  [DYSFUNCTION_NAMES.SKIN_TEXTURE]: 6,
  [DYSFUNCTION_NAMES.PIMPLES]: 5,
  [DYSFUNCTION_NAMES.SKIN_PIGMENTATION]: 2
};

export default {
  name: 'PageIntroTemplate',
  components: { QuestionnaireLanguageDropdown, UButton, SpiderChart, UIcon },
  mixins: [spiderChartDysfunctionsMixin],
  props: {
    locale: {
      type: String,
      required: true
    },
    languageOptions: {
      type: Array,
      default: () => []
    },
    doctorQuote: {
      type: String,
      required: true
    },
    doctorName: {
      type: String,
      required: true
    }
  },
  computed: {
    pageHeading() {
      return this.$t('label.yourSkinDiagnostic', [this.doctorName]);
    },
    demoDysfunctions() {
      return this.getDysfunctionsList(DEMO_SCORES);
    }
  },
  methods: {
    onStartDiagnostic() {
      this.$emit('start-diagnostic');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/modules/questionnaire/assets/scss/questionnaire-background.scss';
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.page-intro {
  position: relative;
  background-color: var(--u-color-white);
  padding: 95px 24px 24px 24px;

  &__header {
    position: absolute;
    height: 57px;
    width: 100%;
    top: 0;
    left: 0;
  }

  &__gray-background-section {
    background-color: var(--u-color-grey-50);
    border-radius: 10px;
    padding: 24px 16px;
  }
}

.page-intro-header {
  &__language-dropdown {
    position: absolute;
    right: 16px;
    top: 19px;
  }
}

.page-intro-content {
  width: 525px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;

  &:lang(ar) {
    direction: rtl;
  }

  &__section-divider {
    border-bottom: 1px solid var(--u-color-grey-200);
  }
}

.page-intro-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.doctor-info-section {
  &__quote {
    font-family: var(--u-font-family-Helvetica);
    font-style: italic;
    font-size: 16px;
    line-height: 20px;
  }

  &__doctor-signature {
    font-family: var(--u-font-family-Quentin);
    font-size: 17px;
    line-height: 20px;
  }
}

.personalized-chart-section {
  &__ai-tag {
    font-family: var(--u-font-family-Helvetica);
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    background-color: var(--u-color-info);
    color: var(--u-color-white);
    border-radius: 4px;
    padding: 3px 8px 4px 8px;
    display: inline-block;
  }
}

.recommended-ingredients {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin: 0 0 16px 0;
  }

  &__image {
    margin-bottom: 24px;
  }

  &__recommendations-list {
    font-family: var(--u-font-family-Helvetica);
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.4px;
    padding: 0;
    margin: 0;
  }

  &__check-icon {
    --u-icon-size: 18px;
    --u-icon-color: var(--u-color-success);
    margin-right: 16px;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  &__color-square {
    border-radius: 3px;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 8px;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 8px;
    }

    &--skin-dryness {
      background-color: var(--u-color-skin-dryness);
    }

    &--skin-redness {
      background-color: var(--u-color-skin-redness);
    }

    &--oxidative-stress {
      background-color: var(--u-color-oxidative-stress);
    }

    &--skin-appearance {
      background-color: var(--u-color-skin-appearance);
    }
  }
}

.recommended-ingredients-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.best-skin-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    max-width: 100%;
  }
}

.easy-step {
  display: flex;
  text-align: start;

  &__image {
    --image-gap: 16px;
    margin-right: var(--image-gap);

    &:lang(ar) {
      margin-right: 0;
      margin-left: var(--image-gap);
    }
  }

  &__text-block {
  }

  &__title {
    margin: 0 0 4px 0;
  }
}

@media (min-width: 360px) {
  .page-intro {
    &__gray-background-section {
      padding: 32px 24px;
    }
  }
}

@media (min-width: $desktop-start) {
  .page-intro-header {
    &__language-dropdown {
      top: 32px;
    }
  }

  .recommended-ingredients {
    flex-direction: row;

    &__image {
      margin-bottom: 0;
      margin-right: 24px;

      &:lang(ar) {
        margin-right: 0;
        margin-left: 24px;
      }
    }
  }

  .easy-step {
    &__image {
      --image-gap: 24px;
      width: 85px;
      height: 85px;
    }
  }
}
</style>
