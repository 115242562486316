<template>
  <div>
    <page-intro-template
      :doctor-name="doctorFullName"
      :doctor-quote="doctorQuote"
      :locale="locale"
      :language-options="languageOptions"
      @change-language="onLanguageSelect"
      @start-diagnostic="onStartSkinDiagnostic"
    />

    <cookie-consent-new-design class="cookie-consent" />
    <continue-questionnaire-modal
      @continue="continueQuestionnaire"
      @start-new="startNewQuestionnaire"
    />
  </div>
</template>

<script>
import { sample } from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import PageIntroTemplate from '@/modules/questionnaire/pages/page-intro/PageIntroTemplate';
import CookieConsentNewDesign from '@/modules/dashboard/components/common/CookieConsentNewDesign';
import ContinueQuestionnaireModal from '@/modules/questionnaire/components/dialogs/ContinueQuestionnaireModal';

import { canContinueQuestionnaire, getDecodedId } from '@/utils';
import { getDoctorFullName } from '@/modules/questionnaire/api';
import { getQuestionnaireLocaleOptions } from '@/api/places';
import { prefetchRequiredContexts } from '@/utils/prefetchRequiredContexts';

import rootTypes from '@/store/types';
import { types } from '@/modules/questionnaire/store/types';

const DOCTOR_QUESTIONNAIRE_REGEXP = /^\/q\/d/;

const DOCTOR_QUOTES = [
  'startPage.quotation1',
  'startPage.quotation2',
  'startPage.quotation3',
  'startPage.quotation4',
  'startPage.quotation5',
  'startPage.quotation6',
  'startPage.quotation7',
  'startPage.quotation8',
  'startPage.quotation9',
  'startPage.quotation10',
  'startPage.quotation11',
  'startPage.quotation12',
  'startPage.quotation13',
  'startPage.quotation14',
  'startPage.quotation15'
];

export default {
  name: 'PageIntro',
  components: {
    PageIntroTemplate,
    CookieConsentNewDesign,
    ContinueQuestionnaireModal
  },
  data() {
    return {
      canContinue: false,
      doctorFullName: '',
      setupQuestionnaireData: null
    };
  },
  computed: {
    ...mapGetters({
      doctor: types.getters.GET_DOCTOR,
      locale: rootTypes.getters.GET_LOCALE
    }),
    languageOptions() {
      return getQuestionnaireLocaleOptions();
    },
    doctorQuote() {
      return sample(DOCTOR_QUOTES);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const isDoctorsQuestionnaire = DOCTOR_QUESTIONNAIRE_REGEXP.test(to.fullPath);

      vm.setupQuestionnaireData = { to, from, isDoctorsQuestionnaire };

      if (canContinueQuestionnaire() && isDoctorsQuestionnaire) {
        vm.canContinue = true;

        vm.$modal.show('continue-questionnaire-modal');
      } else {
        vm.setQuestionnaire();
      }
    });
  },
  async mounted() {
    const [id] = getDecodedId(this.$route.params.hash || '');

    const {
      data: { doctorName }
    } = await getDoctorFullName(id);
    this.doctorFullName = doctorName || '';

    this.prefetchQuestionnaireResources();
  },
  methods: {
    ...mapMutations({
      setInfluencer: types.mutations.SET_INFLUENCER
    }),
    ...mapActions({
      setLocale: rootTypes.actions.SET_LOCALE,
      setupQuestionnaire: types.actions.SETUP_QUESTIONNAIRE
    }),
    async setQuestionnaire() {
      const { errors } = await this.setupQuestionnaire(this.setupQuestionnaireData);
      const hasErrors = errors && errors.length > 0;

      const { influencer } = this.$route.query;

      this.setInfluencer(influencer);

      if (hasErrors) {
        await this.$router.replace({ name: 'PageNotFound' });
      }
    },
    onLanguageSelect({ code }) {
      this.setLocale(code);
    },
    onStartSkinDiagnostic() {
      this.$router.replace({
        name: 'GetAcquainted',
        params: { doctorHash: this.$route.params.hash }
      });
    },
    continueQuestionnaire() {
      this.$router.replace({ name: 'Questions' });
    },
    startNewQuestionnaire() {
      this.$modal.hide('continue-questionnaire-modal');

      this.setQuestionnaire();
      this.canContinue = false;
    },
    async prefetchQuestionnaireResources() {
      const questionnaireAssetsContext = require.context('@/modules/questionnaire/assets', true);
      const photoInstructions = require.context('@/assets/images/photo-instructions-icons', true);

      prefetchRequiredContexts([photoInstructions, questionnaireAssetsContext]);
    }
  },
  metaInfo: {
    title: 'Questionnaire',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
}
</style>
