<template>
  <questionnaire-base-modal
    :title="$t('validationMessage.title.incompletedQuestionnaire')"
    name="continue-questionnaire-modal"
  >
    <template #main-content>
      <i18n class="time-limit-exceed-modal__content" tag="p" path="info.questionnaireContinue">
        <span place="date">{{ questionnaireCreationDate }}</span>
        <b place="patientEmail">{{ patientEmail }}</b>
      </i18n>
    </template>

    <template #actions>
      <div class="continue-questionnaire-modal__action-buttons continue-action-buttons">
        <u-button
          class="continue-action-buttons__button-continue"
          kind="primary"
          @click="onContinue"
          >{{ $t('action.yesContinue') }}
        </u-button>
        <u-button class="continue-action-buttons__button-start" kind="secondary" @click="onStartNew"
          >{{ $t('action.startNew') }}
        </u-button>
      </div>
    </template>
  </questionnaire-base-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import { UButton } from 'universkin-shared';

import QuestionnaireBaseModal from '@/modules/questionnaire/components/dialogs/QuestionnaireBaseModal';

import { isMobileDevice } from '@/modules/dashboard/utils';

import { types } from '@/modules/questionnaire/store/types';

export default {
  name: 'ContinueQuestionnaireModal',
  components: { UButton, QuestionnaireBaseModal },
  computed: {
    ...mapGetters({
      patient: types.getters.GET_PATIENT,
      questionnaireCreationDate: types.getters.GET_QUESTIONNAIRE_CREATION_DATE
    }),
    patientEmail() {
      return this.patient.email || '';
    },
    modalMaxWidth() {
      return isMobileDevice() ? 328 : 550;
    }
  },
  methods: {
    onContinue() {
      this.$emit('continue');
    },
    onStartNew() {
      this.$emit('start-new');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';
@import '~universkin-shared/src/assets/styles/scss/sizes';
@import '~universkin-shared/src/assets/styles/scss/typography-helvetica.scss';

.continue-questionnaire-modal {
  &__content {
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-graphite;
    letter-spacing: 0.4px;
    text-align: center;
  }
}

.continue-action-buttons {
  display: flex;
  flex-direction: column;

  &__button-continue {
    margin-bottom: 8px;
  }
}

@media (min-width: $desktop-start) {
  .continue-questionnaire-modal {
    &__content {
      font-size: 16px;
      line-height: 32px;
    }
  }

  .continue-action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__button-continue {
      margin: 0 8px 0 0;
    }

    &__button-start {
      margin-left: 8px;
    }
  }
}
</style>
