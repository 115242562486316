const DYSFUNCTION_NAMES = {
  OXIDATIVE_STRESS: 'oxidativeStress',
  SKIN_APPEARANCE: 'skinAppearance',
  SKIN_REDNESS: 'skinRedness',
  SKIN_DRYNESS: 'skinDryness',
  SKIN_OILINESS: 'skinOiliness',
  SKIN_TEXTURE: 'skinTexture',
  PIMPLES: 'pimples',
  SKIN_PIGMENTATION: 'skinPigmentation'
};

const DISPLAY_NAME = {
  [DYSFUNCTION_NAMES.OXIDATIVE_STRESS]: {
    firstPart: 'spiderChart.oxidativeStress.label'
  },
  [DYSFUNCTION_NAMES.SKIN_APPEARANCE]: {
    firstPart: 'spiderChart.skinAppearance.label'
  },
  [DYSFUNCTION_NAMES.SKIN_REDNESS]: { firstPart: 'spiderChart.skinRedness.label' },
  [DYSFUNCTION_NAMES.SKIN_DRYNESS]: {
    firstPart: 'spiderChart.skinDryness.labelFirstPart',
    secondPart: 'spiderChart.skinDryness.labelSecondPart'
  },
  [DYSFUNCTION_NAMES.SKIN_OILINESS]: {
    firstPart: 'spiderChart.skinOiliness.label'
  },
  [DYSFUNCTION_NAMES.SKIN_TEXTURE]: { firstPart: 'spiderChart.skinTexture.label' },
  [DYSFUNCTION_NAMES.PIMPLES]: { firstPart: 'spiderChart.pimples.label' },
  [DYSFUNCTION_NAMES.SKIN_PIGMENTATION]: {
    firstPart: 'spiderChart.skinPigmentation.labelFirstPart',
    secondPart: 'spiderChart.skinPigmentation.labelSecondPart'
  }
};

export const spiderChartDysfunctionsMixin = {
  methods: {
    getDysfunctionsList(scores) {
      return Object.values(DYSFUNCTION_NAMES).map(dysfunctionName => {
        const score = scores[dysfunctionName];
        const { firstPart, secondPart } = DISPLAY_NAME[dysfunctionName];
        const displayName = {
          firstPart: this.$t(firstPart),
          secondPart: secondPart ? this.$t(secondPart) : ''
        };

        return { dysfunctionName, score, displayName };
      });
    }
  }
};
